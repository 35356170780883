import React, { useState } from 'react'
import {
    differenceInDays
} from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
    Container,
    ContainerBack,
    ContainerContador
} from './styles'

export default function HomePage() {
    const { push } = useHistory();
    const [dias, setDias] = useState("0");
    const [horas, setHoras] = useState("0");
    const [minutos, setMinutos] = useState("0");
    const [segundos, setSegundos] = useState("0");

    setInterval(
        () => {
            var dataOficial = new Date(2020, 1, 21, 20, 0)
            var hoje = new Date()

            var ss = parseInt((dataOficial - hoje) / 1000);
            var mm = parseInt(ss / 60);
            var hh = parseInt(mm / 60);
            var dd = parseInt(hh / 24);

            ss = ss - (mm * 60);
            mm = mm - (hh * 60);
            hh = hh - (dd * 24);
            setDias(differenceInDays(dataOficial, hoje))
            setHoras(hh)
            setMinutos(mm)
            setSegundos(ss)
        },
        1000
    );

    function handleNext() {
        push("/regulamento")
    }

    return (
        <Container>
            <ContainerBack style={{background: "rgba(0,0,0,.9)", flex:1, minHeight: "100%" }}>
                <div className="container p-sm-5">
                    <h1>ACAMP <br />RENOVANDO MENTES</h1>
                    <p>
                        <span>SEJA BEM VINDO</span><br/>
                        Aqui você vai pode se inscrever para o Acamp 2020!<br />
                        O tema desse ano é <b>renovando mentes</b>, e temos certeza
                        que após esses dias você será completamente renovado
                        em todas as áreas. Estamos ansiosos para recebê-lo,
                        então prossiga para ficha de inscrição
                        e prepare-se para o melhor Acamp de todos os tempos!
                    </p>
                    <ContainerContador>
                        <li>
                            <h3>{dias}</h3>
                            <span>{dias > 1? "dias" : "dia"}</span>
                        </li>
                        <li>
                            <h3>{horas}</h3>
                            <span>{horas > 1? "horas" : "hora"}</span>
                        </li>
                        <li>
                            <h3>{minutos}</h3>
                            <span>{minutos > 1? "minutos" : "minuto"}</span>
                        </li>
                        <li>
                            <h3>{segundos}</h3>
                            <span>{segundos > 1? "segundos" : "segundo"}</span>
                        </li>
                    </ContainerContador>
                    <button onClick={handleNext}>Vamos lá</button>
                </div>
            </ContainerBack>
        </Container>
    );
}
