import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import RegulamentoPage from './pages/RegulamentoPage'
import InscricaoPage from './pages/InscricaoPage'
import SucessoPage from './pages/SucessoPage'

function Routes() {
    return(
        <BrowserRouter>
            <Route path="/" exact component={HomePage} />
            <Route path="/regulamento" component={RegulamentoPage} />
            <Route path="/inscricao" component={InscricaoPage} />
            <Route path="/sucesso" component={SucessoPage} />
        </BrowserRouter>
    );
}

export default Routes;