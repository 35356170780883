/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import {
    Input,
    RadioG,
    RadioI,
    RadioIA
} from '../../components/ItensForms'
import { useHistory } from 'react-router-dom'
import {
    Container,
    ContainerBack
} from './styles'
import api from '../../services/api'
import Upload from "../../components/Upload";

export default function InscricaoPage() {
    const { replace } = useHistory();

    const [nome, setNome] = useState("");
    const [idade, setIdade] = useState("");
    const [sexo, setSexo] = useState("Masculino");
    const [dataNasc, setDataNasc] = useState("");
    const [RG, setRG] = useState("");
    const [CPF, setCPF] = useState("");
    const [pai, setPai] = useState("");
    const [mae, setMae] = useState("");
    const [membro, setMembro] = useState("Sim");
    const [alergico, setAlergico] = useState("Não");
    const [aalergico, setAAlergico] = useState("");
    const [medicamento, setMedicamento] = useState("Não");
    const [amedicamento, setAMedicamento] = useState("");
    const [rua, setRua] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [UF, setUF] = useState("");
    const [telefone, setTelefone] = useState("");
    const [celular, setCelular] = useState("");
    const [email, setEmail] = useState("");
    const [FormaPag, setFormaPag] = useState("A VISTA - 1 x R$ 180,00");
    const [vencimento, setVencimento] = useState("");
    const [foto, setFoto] = useState("");

    function showInp(rad, inp) {
        if (rad.show()[0].checked) {
            inp.removeClass('d-none')
        } else {
            inp.addClass('d-none')
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        var idAcampante = Math.floor(Math.random() * (9999 - 100)) + 100;;
        const data = {
            idAcampante,
            nome,
            idade,
            sexo,
            dataNasc,
            RG,
            CPF,
            pai,
            mae,
            membro,
            alergico,
            aalergico,
            medicamento,
            amedicamento,
            rua,
            numero,
            bairro,
            cidade,
            UF,
            telefone,
            celular,
            email,
            FormaPag,
            vencimento,
            foto
        };
        if (nome === "" || foto === "") {
            alert("Preencha todos os campos para prosseguir")
            return
        }
        const res = await api.post("/fazerInscricao", { ...data })
        if (res.data.affectedRows > 0) {
            replace(`/sucesso?id=${idAcampante}`)
        } else {
            replace('/')
            alert("Ocorreu um erro ao registrar a inscrição, por favor tente mais tarde")
        }
    }

    async function handleUpload(files) {
        var file = files[0]
        const reader = new FileReader();
        reader.onload = (event) => {
            setFoto(event.target.result)
        };
        reader.readAsDataURL(file);
    }


    return (
        <Container>
            <ContainerBack style={{ background: "rgba(0,0,0,.9)", flex: 1, minHeight: "100%" }}>
                <h1 className="p-3">Formulário de inscrição</h1>
                <div className="row">
                    <div className="col-12 col-sm-12 p-5">
                        <form>
                            <div className='row'>
                                <Input span="NOME DO ACAMPANTE:" size="col col-sm-10 col-9" value={nome} onChange={setNome} UpperCase />
                                <Input span="IDADE:" size="col col-sm-2 col-3" type="number" value={idade} onChange={setIdade} />

                                <RadioG titulo="SEXO:" size="col-5 col-sm-4">
                                    <RadioI id="masc" value="Masculino" name="sexo" onChange={setSexo} checked />
                                    <RadioI id="fem" value="Feminino" name="sexo" onChange={setSexo} />
                                </RadioG>
                                <Input span="DATA DE NASCIMENTO:" size="col col-sm-8 col-7" type="date" value={dataNasc} onChange={setDataNasc} />

                                <Input span="RG:" size="col col-12 col-sm-6" maxLength={9} value={RG} onChange={setRG} />
                                <Input span="CPF:" size="col col-12 col-sm-6" maxLength={11} value={CPF} onChange={setCPF} />

                                <Input span="NOME DO PAI:" size="col col-12" value={pai} onChange={setPai} UpperCase />
                                <Input span="NOME DO MÃE:" size="col col-12" value={mae} onChange={setMae} UpperCase />

                                <RadioG titulo="MEMBRO DA IAAV:" size="col-6 col-sm-4">
                                    <RadioI id="simM" value="Sim" name="membro" onChange={setMembro} checked />
                                    <RadioI id="naoM" value="Não" name="membro" onChange={setMembro} />
                                </RadioG>
                                <RadioG titulo="É ALERGICO?" size="col-6 col-sm-4">
                                    <RadioIA
                                        id1="simA"
                                        id2="naoA"
                                        value1="Sim"
                                        value2="Não"
                                        name="alergico"
                                        dA="alergico"
                                        dATitle="A QUE?"
                                        showInp={showInp}
                                        onChange={setAlergico}
                                        onChangeA={setAAlergico}
                                        valueA={aalergico}
                                    />
                                </RadioG>
                                <RadioG titulo="USA MEDICAMENTO?" size="col-12 col-sm-4">
                                    <RadioIA
                                        id1="simMED"
                                        id2="naoMED"
                                        value1="Sim"
                                        value2="Não"
                                        name="medicamento"
                                        dA="medicamento"
                                        dATitle="QUAL?"
                                        nameA="qual"
                                        info="* Caso use algum medicamento deve ser levado, á ministração é de seu total responsabilidade."
                                        showInp={showInp}
                                        onChange={setMedicamento}
                                        onChangeA={setAMedicamento}
                                        valueA={amedicamento}
                                    />
                                </RadioG>

                                <Input span="RUA:" size="col col-12 col-sm-10" type="text" value={rua} onChange={setRua} UpperCase />
                                <Input span="NUMERO:" size="col col-4 col-sm-2" type="number" value={numero} onChange={setNumero} />

                                <Input span="BAIRRO:" size="col col-sm-6 col-8" type="text" value={bairro} onChange={setBairro} UpperCase />
                                <Input span="CIDADE:" size="col col-sm-4 col-8" type="text" value={cidade} onChange={setCidade} UpperCase />
                                <Input span="UF:" size="col col-sm-2 col-4" type="text" value={UF} onChange={setUF} UpperCase />
                                <Input span="TELEFONE:" size="col col-12 col-sm-6" maxLength={10} type="text" value={telefone} onChange={setTelefone} />
                                <Input span="CELULAR:" size="col col-12 col-sm-6" maxLength={11} type="text" value={celular} onChange={setCelular} />
                                <Input span="EMAIL:" size="col col-12" type="email" value={email} onChange={setEmail} />
                                <RadioG titulo="FORMA DE PAGAMENTO" size="col col-12 col-sm-6">
                                    <RadioI id="vista" name="formaPag" value="A VISTA - 1 x R$ 180,00" onChange={setFormaPag} checked />
                                    <RadioI id="parcelado" name="formaPag" value="PARCELADO - 2 x R$ 90,00" onChange={setFormaPag} />
                                </RadioG>
                                <Input span="DATA DE VENCIMENTO DA 1ª PARCELA:" size="col col-sm-6 col-12 mb-4" type="date" value={vencimento} onChange={setVencimento} />

                                <div className="col col-12 mt-6 col-sm-12 ">
                                    <label className="col-12 mb-2">FOTO DE IDENTIFICAÇÃO:</label>
                                    <div className="drop col-12 col-sm-12">
                                        <Upload onUpload={handleUpload} />
                                    </div>
                                    <div className="col col-12 col-sm-12">
                                        {
                                            foto ?
                                                <div class="row">
                                                    <div style={{
                                                        backgroundImage: `url(${foto})`,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: 400,
                                                        maxHeight: 400,
                                                        marginTop: 10,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "100%",
                                                        backgroundPosition: "top",
                                                        borderColor: "#fff"
                                                    }} />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="col col-12">
                            <button className="btn btn-md btn-block btn-dark px-3 pt-3 button" onClick={(e) => handleSubmit(e)} ><h4>INSCREVER-SE</h4></button>
                        </div>
                    </div>
                </div>
            </ContainerBack>
        </Container>
    );
}
