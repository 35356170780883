import styled from 'styled-components';
import background from '../../assets/fundo.jpg'
import { device } from '../../assets/devices';

export const Container = styled.div`    
    @import url('https://fonts.googleapis.com/css?family=Monoton&display=swap');
    min-width:100%;
    min-height:100%;
    background-image: url('${background}');
    background-position: center center;
    color: #fff;
    h1 {
        font-family: 'Monoton', cursive;
        color: #fefefe;
        font-size: 5vw;
    }
    p{
        margin-top:50px;
        text-align: justify;
        span{
            font-size: 3vw;
        }
        font-size: 1.6vw;
    }
    button{
        border: 0;
        float: right;
        background: linear-gradient(rgb(215, 128, 209), rgb(1,164, 199));
        padding: 0.5vw 2vw 0.5vw 2vw;
        border-radius: 1vw;
        color: #fefefe;
        font-size: 1.6vw;
        margin: 23px 0 40px 0;
        box-shadow: px 1px 5px #001;
    }
    button:hover{
        background: linear-gradient(rgba(215, 128, 209,.8), rgba(1,164, 199,.8));
        color: #fefefe;
    }

    @media ${device.mobileR} {
        height:100%;
        h1 {
            font-family: 'Monoton', cursive;
            color: #fefefe;
            font-size: 10vw;
            margin-top:20px;
            text-align: center
        }
        p{
            text-align: center;
            margin-top:30px;
            span{
                font-size: 7vw;
            }
            font-size: 3.9vw;
        }

        button{
            font-size: 5vw;
            padding: 1.5vw 3vw 1.5vw 3vw;
        }
    }
`;

export const ContainerBack = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerContador = styled.ul`
    flex:1;
    width: 100%;
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    li {
        padding: 10px;
        margin: 10px;
        display:flex;
        max-width: 25%;
        min-width: 25%;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        h3 {
            font-size: 5vw;
        }
        span {
            color: rgb(215, 128, 209);
            font-size:3vw;
        }
    }

    @media ${device.mobileR} {
        li {
            padding: 10px;
            margin: 10px;
            max-width: 25%;
            min-width: 25%;
            flex-direction: column;
            justify-content: center;
            align-items:center;
            h3 {
                font-size: 10vw;
            }
            span {
                color: rgb(215, 128, 209);
                font-size:4vw;
            }
        }
    }
`;
