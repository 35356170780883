import styled from 'styled-components';
import background from '../../assets/fundo.jpg'
import { device } from '../../assets/devices';

export const Container = styled.div`    
    @import url('https://fonts.googleapis.com/css?family=Monoton&display=swap');
    min-width:100%;
    min-height:100%;
    background-image: url('${background}');
    background-position: center center;
    background-size: 100%;
    color: #fff;
    h1 {
        font-family: 'Monoton', cursive;
        color: #fefefe;
        font-size: 5vw;
        margin: 50px 0;
    }
    p{
        margin-top:50px;
        text-align: justify;
        span{
            font-size: 36pt;
        }
        font-size: 16pt;
    }
    button.button{
        border: 0;
        float: right;
        background: linear-gradient(rgb(215, 128, 209), rgb(1,164, 199));
        padding: 0.5vw 2vw 0.5vw 2vw;
        border-radius: 1vw;
        color: #fefefe;
        font-size: 1.2vw;
        margin-top  : 40px;
        box-shadow: px 1px 5px #001;
    }
    button.button:hover{
        background: linear-gradient(rgba(215, 128, 209,.8), rgba(1,164, 199,.8));
        color: #fefefe;
    }
    @media ${device.tablet2} {
        height:100%;
        h1 {
            font-size: 13vw;
            margin: 0 0 0 10px;
        }
        button .button{
            font-size: 4vw;
            margin: 0 10px 50px 0;
        }
    }
`;

export const ContainerBack = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.tablet2} {
        display: grid;
        flex-direction: column;
        padding-top: 30px;
        justify-content: center;
        align-items: center;
    }
`;
