import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    Container,
    ContainerBack
} from './styles'

export default function RegulamentoPage() {
    const { push } = useHistory();

    function handleNext() {
        push("/inscricao")
    }

    return (
        <Container>
            <ContainerBack style={{background: "rgba(0,0,0,.9)", flex:1, minHeight: "100%" }}>
                <div className="container">
                    <h1>REGULAMENTO DO ACAMP</h1>
                    <div>
                        <p>
                            <b>1 - </b> A inscrição para o evento implica na concordância plena deste regulamento.<br/><br/>
                            <b>2 - </b> O ACAMP terá início no dia 21 de fevereiro, sexta-feira.<br/><br/>
                            <b>3 - </b> Entrada partir das 18h até às 21h. Não será servida refeição neste dia.<br/><br/>
                            <b>4 - </b> O retorno será no dia 25 de fevereiro, terça-feira, ao meio-dia, neste dia será servido café da manhã e almoço. Cada um será responsável pelo seu transporte.<br/><br/>
                            <b>5 - </b> Só será permitida a entrada no local de quem fez a inscrição. Os inscritos deverão estar com a pulseira de identificação em todo tempo.<br/><br/>
                            <b>6 - </b> Os menores de 18 anos caso não estejam acompanhados, devem estar autorizados pelos pais ou responsáveis.<br/><br/>
                            <b>7 - </b> No caso dos menores desacompanhados, é necessário apontar um líder responsável pelo acampante.<br/><br/>
                            <b>8 - </b> Os acampantes deverão submeter-se à autoridade do acampamento, equipe e aos funcionários do local.<br/><br/>
                            <b>9 - </b> Divisão dos quartos: o acampamento está dividido em duas alas, cada uma com um grupo de quartos; uma será destinada às meninas, outra aos meninos. Não será permitida a presença de meninas nos quartos masculinos, nem dos meninos nos quartos femininos. É proibido invasão nos quartos.<br/><br/>
                            <b>10 - </b> Cada quarto terá um monitor que será responsável pela ordem e que levará qualquer intercorrência até o líder do acampante.<br/><br/>
                            <b>11 - </b> As atividades do evento limitam-se ao espaço físico da Acampamento. Os menores de 18 anos só poderão sair do Acampamento com comunicação e anuência dos pais que por vez terão de buscá-los. Os maiores de 18 anos devem notificar a direção qualquer saída do Acampamento.<br/><br/>
                            <b>12 - </b> Aconselhamos a participarem de todas as atividades, refeições e assistirem todos os cultos nos horários estabelecidos pela liderança.<br/><br/>
                            <b>13 - </b> Pedimos aos casais compromissados que não fiquem em lugares isolados e que evitem contato físico no acampamento.<br/><br/>
                            <b>14 - </b> Não é permitido bebida alcoólica, cigarros, nem qualquer outro tipo de substância entorpecente no acampamento.<br/><br/>
                            <b>15 - </b> Comportamento e vestimenta: As vestimentas e o comportamento devem ser adequados ao padrão cristão do evento. Não aceitaremos atitudes indisciplinares e palavras de baixo calão. Use o bom senso tanto para se vestir como para se comportar. Na área da piscina todos devem estar de short e camiseta.<br/><br/>
                            <b>16 - </b> Levar roupa de cama, cobertor, travesseiro, objetos de higiene pessoal, bíblia e caderno para anotações.<br/><br/>
                            <b>17 - </b> Levar roupas condizentes às atividades, como roupas que possam ser sujas para o caso da gincana, roupas para a Festa Neon, Personagens (de filmes, séries, desenhos, games ou bíblicos) e Lual Tropical.<br/><br/>
                            <b>18 - </b> Não nos responsabilizaremos por perdas, roubos e danos de qualquer objeto ou vestimenta trazido pelo acampante, portanto cuide dos seus pertences.<br/><br/>
                            <b>19 - </b> Estrutura do acampamento: caso algum objeto ou mesmo a estrutura do acampamento seja danificada por má conduta ou indisciplina do acampante, o mesmo se responsabilizará por tal dano, ressarcindo o acampamento após comunicar seus pais.<br/><br/>
                            <b>20 - </b> Caso o acampante faça uso de medicamentos será necessário leva-los ao Acampamento, e a ministração do mesmo será de plena responsabilidade do acampante. Caso o acampante seja menor de idade é obrigatório informar o líder do quarto quanto aos horários da medicação.<br/><br/>
                            <b>21 - </b> Em caso do não cumprimento deste regulamento, o acampante será orientado pela direção do acampamento, podendo ser convidado a se retirar do evento, estando sujeito a não participar da próxima temporada.<br/><br/>
                            <b>22 - </b> Os casos omissos deste regulamento serão levados à direção do acampamento que tomará as devidas providências.<br/><br/>
                        </p>
                    </div>
                    <button onClick={handleNext}>Beleza!</button>
                </div>
            </ContainerBack>
        </Container>
    );
}
