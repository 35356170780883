import React, { useState, useEffect } from 'react'
import {
    differenceInDays
} from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
    Container,
    ContainerBack,
    ContainerContador
} from './styles'

export default function SucessoPage({location}) {
    const { push } = useHistory();
    const [numberCamp, setNumberCamp] = useState("0");
    const [dias, setDias] = useState("0");
    const [horas, setHoras] = useState("0");
    const [minutos, setMinutos] = useState("0");
    const [segundos, setSegundos] = useState("0");

    useEffect(() => {
        setNumberCamp(location.search.split('=')[1])
    }, [location.search])

    setInterval(
        () => {
            var dataOficial = new Date(2020, 1, 21, 20, 0)
            var hoje = new Date()

            var ss = parseInt((dataOficial - hoje) / 1000);
            var mm = parseInt(ss / 60);
            var hh = parseInt(mm / 60);
            var dd = parseInt(hh / 24);

            ss = ss - (mm * 60);
            mm = mm - (hh * 60);
            hh = hh - (dd * 24);
            setDias(differenceInDays(dataOficial, hoje))
            setHoras(hh)
            setMinutos(mm)
            setSegundos(ss)
        },
        1000
    );

    function handleNext() {
        push("/regulamento")
    }

    return (
        <Container>
            <ContainerBack style={{background: "rgba(0,0,0,.9)", flex:1, minHeight: "100%" }}>
                <div className="container">
                    <h1>INSCRIÇÃO REGISTRADA COM SUCESSO</h1>
                    <p>
                    <span><b>NUMBERCAMP # {numberCamp}</b></span><br/>
                    Pronto, agora você só precisa procurar algum de nossos representantes e <b>APRESENTAR O SEU NUMBERCAMP</b> para realizar seu pagamento e validar sua inscrição. 
                    Aumente suas expectativas e arrume as malas pra viver os melhores dias do ano!
                    </p>
                    <ContainerContador>
                        <li>
                            <h3>{dias}</h3>
                            <span>{dias > 1? "dias" : "dia"}</span>
                        </li>
                        <li>
                            <h3>{horas}</h3>
                            <span>{horas > 1? "horas" : "hora"}</span>
                        </li>
                        <li>
                            <h3>{minutos}</h3>
                            <span>{minutos > 1? "minutos" : "minuto"}</span>
                        </li>
                        <li>
                            <h3>{segundos}</h3>
                            <span>{segundos > 1? "segundos" : "segundo"}</span>
                        </li>
                    </ContainerContador>
                    <button onClick={handleNext}>Nova inscrição</button>
                </div>
            </ContainerBack>
        </Container>
    );
}
