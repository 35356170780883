import React from 'react'

import $ from 'jquery';

export const Titulo = props => {
    return(
        <div className={props.size+" text-white"}>
            <h3>{props.titulo}</h3>
        </div> 
    )
}

export const Input = props => {
    return(
        <div style={{lineHeight: 2}} className={`my-1 ${props.size}`}>
            <span>{props.span}</span><br/>
            <input type={props.type} className="form-control" value={props.value} maxLength={props.maxLength} onChange={e => props.onChange(props.UpperCase ? e.target.value.toUpperCase() : e.target.value)} required/>
        </div>
    )
}

export const RadioG = props => {
    return(
        <div className={`${props.size} my-3`}>
           <span className="lead">{props.titulo}</span>
           {props.children}
        </div>
    )
}

export const RadioI = props => {
    return(
        (props.checked)?
            <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id={props.id} value={props.value} onChange={e => props.onChange(e.target.value)} name={props.name} defaultChecked />
                <label className="custom-control-label text-white h5" htmlFor={props.id}>{props.value}</label>
            </div>
        :    
            <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id={props.id} value={props.value} onChange={e => props.onChange(e.target.value)} name={props.name} />
                <label className="custom-control-label text-white h5" htmlFor={props.id}>{props.value}</label>
            </div>
    )
}

export const RadioIA = props => {
    return(
        <div>
            <div className="custom-control custom-radio ">
                <input type="radio" className="custom-control-input" id={props.id1} value={props.value1} name={props.name} onChange={e => props.onChange(e.target.value)} onClick={() => {props.onChangeA(""); props.showInp($("#"+props.id1), $("#"+props.dA))}}/>
                <label className="custom-control-label text-white h5" htmlFor={props.id1}>{props.value1}</label>
            </div>
            <div className='d-none mt-2' id={props.dA}>
                <span className="lead">{props.dATitle}</span><br/>
                <input type="text" className="form-control mb-1" onChange={e => props.onChangeA(e.target.value)} value={props.valueA}/>
                <p className="h6 info">{props.info}</p>
            </div>
            <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id={props.id2} value={props.value2} name={props.name} onChange={e => props.onChange(e.target.value)} defaultChecked onClick={() => {props.onChangeA(""); props.showInp($("#"+props.id1), $("#"+props.dA))}}/>
                <label className="custom-control-label text-white h5" htmlFor={props.id2}>{props.value2}</label>
            </div>
        </div>
    )
}

